import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "email"
  ]

  // Google Analytics
  submit() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'newPractitionerLead',
      email: this.emailTarget.value,
    });
  }
}
