import accounting from "accounting";
import countryCurrencyMap from 'country-currency-map';

export function initializeAccountingSettings() {
  const settings = window.AppSettings.currency;
  if (!settings) {
    return accounting;
  }

  accounting.settings = {
    currency: {
      symbol: settings.symbol,
      format: settings.symbolFirst ? "%s%v" : "%v%s",
      decimal: settings.decimalMark,
      thousand: settings.thousandsSeparator,
      precision: 2
    },
    number: {
      precision: 2,
      thousand: settings.thousandsSeparator,
      decimal: settings.decimalMark
    }
  };

  return accounting;
}

export function formatMoney(value) {
  const accounting = initializeAccountingSettings();
  return accounting.formatMoney(value);
}

export function getCurrencyByCountryCode(countryCode) {
  const country = countryCurrencyMap.getCountryByAbbreviation(countryCode);
  const currency = countryCurrencyMap.getCurrencyAbbreviation(country);
  return currency;
}