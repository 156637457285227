const SMALL_TABLET_WIDTH = 960;

document.addEventListener("turbo:load", () => {
  window.addEventListener("resize", loadLazyVideo);
  if (window.innerWidth >= SMALL_TABLET_WIDTH) {
    loadLazyVideo();
    return;
  }
});


const loadLazyVideo = () => {
  if (window.innerWidth < SMALL_TABLET_WIDTH) return;

  window.removeEventListener("resize", loadLazyVideo);

  const lowImage = document.querySelector(".auth-sidebar-media.is-lazy");
  const video = document.querySelector(".auth-sidebar-media.is-full");

  if (!video || !lowImage) return;

  let source = document.createElement('source');
  source.setAttribute('src', video.dataset.src);
  video.appendChild(source);

  video.onloadeddata = () => {
    lowImage.classList.add('is-hidden');
    video.classList.remove('is-hidden');
  }
}
