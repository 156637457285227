// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "objectFitPolyfill";
import "element-closest/browser";

import "./img/sidebar-image-low.jpg";
import "./vid/sidebar-vid.mp4";

// *** JS from node modules
import "bootstrap/dist/js/bootstrap";
import { Turbo } from '@hotwired/turbo-rails';
import RailsUJS from "rails-ujs";

import "bootstrap-notify/bootstrap-notify";

import RollbarClient from "../shared/js/rollbar";

const rollbar = new RollbarClient();
rollbar.connect();

// StimulusJS
import { Application as Stimulus } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
const stimulus = Stimulus.start();
const controllers = require.context("./js/controllers", true, /\.js$/);
stimulus.load(definitionsFromContext(controllers));

const sharedControllers = require.context("../shared/controllers", true, /\.js$/);
stimulus.load(definitionsFromContext(sharedControllers));

if (window.hasOwnProperty("Rollbar")) {
  const defaultErrorHandler = stimulus.handleError;
  const rollbarErrorHandler = (error, message, detail) => {
    defaultErrorHandler(error, message, detail);
    window.Rollbar.error(error);
  };

  stimulus.handleError = rollbarErrorHandler;
}

import "../shared/js/turbolinks.js";
import "./image_blur.js";

// JS Init
RailsUJS.start();
